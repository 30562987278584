import React from "react"
import ArrowZ from "../../../common/ArrowZ"
import Img from "../../../common/Img"

let insideCommon = [
  {
    link: "https://note.com/crispsaladworks/n/ncc5724da4b7b",
    src: "/asset/img/careers/restaurant/20240613.webp",
    src2x: "/asset/img/careers/restaurant/20240613.webp",
    srcWebp: "/asset/img/careers/restaurant/20240613.webp",
    srcWebp2x: "/asset/img/careers/restaurant/20240613.webp",
    alt: "“飲食未経験の第二新卒から、25歳で店長に！”パートナーインタビュー　〜なおやさん編〜｜CRISP",
    ttl: "“飲食未経験の第二新卒から、25歳で店長に！”パートナーインタビュー　〜なおやさん編〜｜CRISP",
  },
  {
    link: "https://note.com/crispsaladworks/n/n98910ef80010",
    src: "/asset/img/careers/restaurant/20240513.webp",
    src2x: "/asset/img/careers/restaurant/20240513.webp",
    srcWebp: "/asset/img/careers/restaurant/20240513.webp",
    srcWebp2x: "/asset/img/careers/restaurant/20240513.webp",
    alt: "CEOと社員でランチ会!?恒例企画「ヒロシとトーク」を取材してみた｜CRISP",
    ttl: "CEOと社員でランチ会!?恒例企画「ヒロシとトーク」を取材してみた｜CRISP",
  },
  {
    link: "https://note.com/crispsaladworks/n/n46ecbcfc753b",
    src: "/asset/img/careers/restaurant/20221227.webp",
    src2x: "/asset/img/careers/restaurant/20221227.webp",
    srcWebp: "/asset/img/careers/restaurant/20221227.webp",
    srcWebp2x: "/asset/img/careers/restaurant/20221227.webp",
    alt: "【振り返り対談】 CEOひろしさん×採用リーダーNorikoさん “個性がうまくブレンドされたカルチャー”ができあがった理由｜CRISP",
    ttl: "【振り返り対談】 CEOひろしさん×採用リーダーNorikoさん “個性がうまくブレンドされたカルチャー”ができあがった理由｜CRISP",
  },
  {
    link: "https://note.com/crispsaladworks/n/n5e29b9f2e901",
    src: "/asset/img/careers/restaurant/20221003.webp",
    src2x: "/asset/img/careers/restaurant/20221003.webp",
    srcWebp: "/asset/img/careers/restaurant/20221003.webp",
    srcWebp2x: "/asset/img/careers/restaurant/20221003.webp",
    alt: "“長年働いたから思う、CRISPで得ることができるもの”CRISPパートナーインタビュー　〜カンカンさん編〜｜CRISP",
    ttl: "“長年働いたから思う、CRISPで得ることができるもの”CRISPパートナーインタビュー　〜カンカンさん編〜｜CRISP",
  },
  {
    link: "https://note.com/crispsaladworks/n/ncd2cf1e1c3a5",
    src: "/asset/img/careers/restaurant/20220927.webp",
    src2x: "/asset/img/careers/restaurant/20220927.webp",
    srcWebp: "/asset/img/careers/restaurant/20220927.webp",
    srcWebp2x: "/asset/img/careers/restaurant/20220927.webp",
    alt: "“採用担当者にCRISPで楽しく働けるひと、そうでないひと！をズバリ聞いてみた”CRISPパートナーインタビュー　〜Norikoさん編〜｜CRISP",
    ttl: "“採用担当者にCRISPで楽しく働けるひと、そうでないひと！をズバリ聞いてみた”CRISPパートナーインタビュー　〜Norikoさん編〜｜CRISP",
  },
  {
    link: "https://note.com/crispsaladworks/n/n8c63d682d59d",
    src: "/asset/img/careers/restaurant/20220829.webp",
    src2x: "/asset/img/careers/restaurant/20220829.webp",
    srcWebp: "/asset/img/careers/restaurant/20220829.webp",
    srcWebp2x: "/asset/img/careers/restaurant/20220829.webp",
    alt: "“フレキシブルな環境で、自分次第で着実にステップアップできる”CRISPパートナーインタビュー　〜しんじさん編〜",
    ttl: "“フレキシブルな環境で、自分次第で着実にステップアップできる”CRISPパートナーインタビュー　〜しんじさん編〜",
  },
]

const Inside = () => {
  return (
    <section className="p-careers__common-inside">
      <div className="p-careers__common-inside__wrapper">
        <div className="p-careers__common-inside__header">
          <h2 className="c-ttl__en p-careers__common-inside__header-ttl">
            THE CRISP INSIDE
          </h2>
        </div>
        <div className="p-careers__common-inside__body">
          <ul className="p-careers__common-inside__body-list">
            {insideCommon.map(data => {
              return (
                <li className="p-careers__common-inside__body-item">
                  <a
                    className="js-hover p-careers__common-inside__body-item-link"
                    data-hover-in-time="600"
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="js-scroll__dark__sp c-link__img p-careers__common-inside__body-item-img">
                      <Img
                        src={data.src}
                        src2x={data.src2x}
                        // webpSrc={data.srcWebp}
                        // webpSrc2x={data.srcWebp2x}
                        alt={data.alt}
                        imgClassName="p-careers__common-inside__body-item-img-item"
                      />
                    </div>
                    <div className="p-careers__common-inside__body-item-content">
                      <h3 className="p-careers__common-inside__body-item-content-ttl">
                        {data.ttl}
                      </h3>
                    </div>
                  </a>
                </li>
              )
            })}
          </ul>
          <a
            className="js-hover p-careers__common-inside__body-link"
            data-hover-in-time="600"
            data-hover-out-time="650"
            href="https://note.com/crispsaladworks/m/m2a8de76fed4b"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="c-link__main p-careers__common-inside__body-link-wrapper">
              <span className="p-careers__common-inside__body-link-span">
                <span className="c-link__main-inner">THE CRISP INSIDE</span>
                <ArrowZ />
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Inside
